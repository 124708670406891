import React from 'react'

const Contact = () => {
  return (
    <div>
      This is contacts.
    </div>
  )
}

export default Contact
